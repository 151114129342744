import ReactPaginate from 'react-paginate';

import ContentLimiter from "../components/ContentLimiter";
import DataTable from "../components/DataTable";

const Leaderboard = () => {

	return (
		<ContentLimiter>
			<div className="m-t-l m-b-l">
				<h1 className="m-b-m">WIN A <span className="primary-color-text">FIGHT PASS</span></h1>
				<h5 className="m-b-s">Join our premium community, be in top 10% of the players and win an award!</h5>

				<p className="m-b-s">By going premium, you will earn multiple benefits like better selling prices.</p>


			</div>

			<div>
				<span className="banner-track primary medium" >Global leaderboard</span>
			</div>

			<div className="m-v-m" >
				<DataTable
					fields={{
						rank: {
							text: "Rank",
							className: "col-1",
							renderer: (item) => {
								if (item.rank === 1)
									return <span className="rank gold">{item.rank}</span>
								if (item.rank === 2)
									return <span className="rank silver">{item.rank}</span>
								if (item.rank === 3)
									return <span className="rank bronze">{item.rank}</span>

								return <span className="rank">{item.rank}</span>
							}
						},
						username: {
							text: "Username",
							className: "col-6",
							renderer: (item) => <a href={`/profile/${item.username}`}>{item.username}</a>
						},
						points: {
							text: "Points",
							className: "col-2",
							renderer: (item) => <span>{item.points}<span className="primary-color-text" > ▲</span></span>
						},
						balance: {
							text: "Balance",
							className: "col-3",
							renderer: (item) => <span>{item.balance} $</span>
						},
					}}
					data={[
						{
							rank: 1,
							username: "VojinBjelica",
							points: "75",
							balance: "825 000",
						},
						{
							rank: 2,
							username: "SlobodanMijajlovic",
							points: "43",
							balance: "417 000",
						},
						{
							rank: 3,
							username: "IvanKockarevic",
							points: "39",
							balance: "362 000",
						},
						{
							rank: 4,
							username: "ticketblood",
							points: "37",
							balance: "418 000",
						},
						{
							rank: 5,
							username: "regimecommand",
							points: "37",
							balance: "417 000",
						},
						{
							rank: 6,
							username: "scriptanvil",
							points: "32",
							balance: "321 000",
						},
						{
							rank: 7,
							username: "simplisticadopt",
							points: "30",
							balance: "305 000",
						},
						{
							rank: 8,
							username: "quadricepsrevelation",
							points: "28",
							balance: "280 000",
						},
						{
							rank: 9,
							username: "spiritualsavings",
							points: "22",
							balance: "144 000",
						},
						{
							rank: 10,
							username: "novembernutter",
							points: "18",
							balance: "132 000",
						},
					]} />

				<div className="pagination-container" >
					<ReactPaginate
						initialPage={1}
						activeClassName={'item active '}
						breakClassName={'item break-me '}
						breakLabel={'...'}
						containerClassName={'pagination'}
						disabledClassName={'disabled-page'}
						marginPagesDisplayed={2}
						nextClassName={"item next "}
						nextLabel={<img src="/images/icons/angle-right-white.svg" alt="" />}
						onPageChange={console.log}
						pageCount={27}
						pageClassName={'item pagination-page '}
						pageRangeDisplayed={2}
						previousClassName={"item previous"}
						previousLabel={<img style={{ transform: "rotateZ(180deg)" }} src="/images/icons/angle-right-white.svg" alt="" />}
					/>
				</div>
			</div>
		</ContentLimiter>
	);
};

export default Leaderboard;