import TextField from "../../components/form/TextField";
import MainCTA from "../../components/form/MainCTA";

const ForgotPassword = () => {

	return (
		<div className="auth-page flex-center">
			<div className="auth-form flex-column">
				<h2 className="m-b-s" >Reset password</h2>

				<div className="m-b-xs" >
					<TextField
						label="E-mail"
						placeholder="e.g. yourvalidemail@domain.com"
						onChange={console.log}
					/>
				</div>

				<span className="disclaimer" >Reset link will be sent to your e-mail address.</span>

				<MainCTA href="/login" className="primary m-t-s" >Request reset</MainCTA>
			</div>
		</div>
	);
};

export default ForgotPassword;