import TextField from "../../components/form/TextField";
import PasswordField from "../../components/form/PasswordField";
import MainCTA from "../../components/form/MainCTA";

const Login = () => {

	return (
		<div className="auth-page flex-center">
			<div className="auth-form flex-column">
				<h2 className="m-b-s" >Log in</h2>

				<div className="m-b-xs" >
					<TextField
						label="E-mail"
						placeholder="e.g. name@domain.com"
						onChange={console.log}
					/>
				</div>

				<div className="m-b-xs" >
					<PasswordField
						label="Password"
						placeholder="e.g. *******"
						onChange={console.log}
					/>
				</div>

				<span className="disclaimer" >If you don't have an account, create one <a href="/register">here</a>. If you forgot your password, retrieve it <a href="/forgot-password">here</a></span>

				<MainCTA href="/lineup" className="primary m-t-s" >Login</MainCTA>
			</div>
		</div>
	);
};

export default Login;