import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toast';

import config from "./config";

import AppLayout from "./layouts/AppLayout";
import PublicLayout from "./layouts/PublicLayout";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import SubmitNewPassword from "./pages/auth/SubmitNewPassword";
import Message from "./pages/Message";
import CheckoutPage from "./pages/CheckoutPage";
import Lineup from "./pages/Lineup";
import Leaderboard from "./pages/Leaderboard";
import Fights from "./pages/Fights";
import Profile from "./pages/Profile";
import PublicProfile from "./pages/PublicProfile";

const App = () => {

	const ProtectedRoute = ({ children }) => {
		if (!localStorage.getItem(config.LOCAL_STORAGE_TOKEN_KEY))
			return <Navigate to="/login" replace />;

		return children;
	};

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Login />} />
				<Route path='/login' element={<Login />} />
				<Route path='/register' element={<Register />} />
				<Route path='/forgot-password' element={<ForgotPassword />} />
				<Route path='/submit-new-password' element={<SubmitNewPassword />} />
				<Route path='/account/reset/finish' element={<Message title="Successfull" message="Your password has been reset. Click the button below to proceed to your homepage." />} />
				<Route path='/account/activate' element={<Message title="Account activated" message="Your account has been activated! Click the button below to proceed to your homepage." />} />

				<Route element={<AppLayout />}>
					<Route path='/lineup' element={<Lineup />} />
					<Route path='/leaderboard' element={<Leaderboard />} />
					<Route path='/fights' element={<Fights />} />
					<Route path='/profile/:username' element={<PublicProfile />} />
					<Route path='/profile' element={<Profile />} />
				</Route>

				<Route element={<ProtectedRoute><AppLayout /></ProtectedRoute>}>
					<Route path='/checkout' element={<CheckoutPage />} />
				</Route>

				<Route element={<PublicLayout />}>
					<Route path='/terms-and-conditions' element={<h1>T&C page</h1>} />
				</Route>
			</Routes>

			<ToastContainer position="bottom-right" delay={5000} />
		</BrowserRouter>
	);
};

export default App;
