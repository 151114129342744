import ContentLimiter from "../components/ContentLimiter";
import MainCTA from "../components/form/MainCTA";
import FightCard from '../components/fight/FightCard';

const Fights = () => {

	const today = new Date();
	const futureDate = new Date();
	futureDate.setDate(today.getDate() + 10);
	const pastDate = new Date();
	pastDate.setDate(today.getDate() - 10);

	return (
		<ContentLimiter>
			<div className="m-t-l m-b-l">
				<h1 className="m-b-m"><span className="primary-color-text">1382</span> FIGHTS SO FAR</h1>
				<h5 className="m-b-s">Check out the following and past fights, either for all fighters or your lineup only.</h5>

				<p>You can check all the fights or for your lineup only.</p>
			</div>

			<div className="multi-btn" >
				<MainCTA className="primary m-r-xs">My lineup</MainCTA>
				<MainCTA>All fights</MainCTA>
			</div>

			<div className="fights-container m-v-m" >
				<FightCard date={futureDate} />
				<FightCard date={futureDate} />
				<FightCard date={today} />
				<FightCard date={pastDate} />
				<FightCard date={pastDate} />

				<span className="centered-text">No more fights to show.</span>
			</div>
		</ContentLimiter>
	);
};

export default Fights;