const DropdownField = ({
    label = "",
    errorMessage = "",
    mandatory = false,
    children = null,
    onChange = () => null,
    value = "",
	white = false,
}) => {
    const errorShown = errorMessage && errorMessage.length;

    return (
        <div className={`dropdown-container ${errorShown ? 'error-shown' : ''}`}>
            {label && label.length && (
                <label className='field-label'>
                    {label}
                    {mandatory && <span className='mandatory'> *</span>}
                </label>
            )}

            <div className={`select-wrapper ${white ? "white" : ""}`}>
                <select
                    defaultValue={value}
                    className="dropdown-field"
                    onChange={(event) => onChange(event.target.value)}>
                    {children}
                </select>

                {!white && <img src='/images/icons/angle-down.svg' alt='dropdown' />}
                {white && <img src='/images/icons/angle-down-white.svg' alt='dropdown' />}
            </div>

            {errorShown && (
                <span className='error-message'>
                    <img className="m-r-xxs" src="/images/icons/error-exclamation-mark.svg" alt="" />
                    {errorMessage}
                </span>
            )}
        </div>
    );
};

export default DropdownField;
