import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";

const Checkout = () => {
	const stripe = useStripe();
	const elements = useElements();

	return (
		<form
			onSubmit={async (e) => {
				e.preventDefault();

				const { error } = await stripe.confirmPayment({
					elements,
					confirmParams: {
						return_url: `${window.location.origin}/completion`,
					},
				});

				if (error) console.log(error);
			}}
		>
			<PaymentElement />

			<button>Submit</button>
		</form>
	);
};

export default Checkout;
