import ReactApexChart from "react-apexcharts";

const DonutStat = ({ percentage = 0, statName = "" }) => {
	return (
		<div className="fighter-donut-stat">
			<ReactApexChart options={{
				chart: {
					width: 380,
					type: 'donut',
				},
				legend: {
					show: false
				},
				dataLabels: {
					enabled: false
				},
				colors: ['#d91f2b', 'transparent'],
				stroke: {
					show: false
				},
				tooltip: {
					enabled: false
				},
				plotOptions: {
					pie: {
						expandOnClick: false
					}
				}
			}} series={[percentage, 100 - percentage]} type="donut" width={160} />

			<span className="donut-stat-value">{ percentage }%</span>
			<span className="donut-stat-name">{ statName}</span>
		</div>
	);
};

export default DonutStat;