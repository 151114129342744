const FighterCard = ({ name = "", division = "", image = "" }) => {
	return (
		<div className="fighter-card" >
			<div className="fighter-image" style={{ backgroundImage: `url(${image})` }}>
			</div>

			<div className="fighter-info">
				{name && <span className="banner-track black medium" >{ name }</span> }
				{division && <span className="banner-track primary small" >{ division }</span> }
			</div>
		</div>
	);
};

export default FighterCard;