import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import "./scss/style.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

navigator.serviceWorker.register("/serviceWorker.js");