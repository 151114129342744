const FighterSlide = () => {
	return (
		<div className="fighter-slide" >
			<div className="fighter-image" style={{ backgroundImage: 'url("/images/fighter-2.webp")' }}>
			</div>

			<div className="fighter-banner-container">
				<div className="breakable-background-text">
					<span className="fighter-name" >Blustin</span>
					<span className="fighter-name" >Baethje</span>
				</div>
				<span className="banner-track primary medium" >Lightweight</span>
			</div>
		</div>
	);
};

export default FighterSlide;