import TextField from "../../components/form/TextField";
import PasswordField from "../../components/form/PasswordField";
import MainCTA from "../../components/form/MainCTA";

const Register = () => {

	return (
		<div className="auth-page flex-center">
			<div className="auth-form flex-column">
				<h2 className="m-b-s" >Create an account</h2>

				<div className="m-b-xs" >
					<TextField
						label="E-mail"
						placeholder="e.g. name@domain.com"
						onChange={console.log}
					/>
				</div>

				<div className="m-b-xs" >
					<TextField
						label="Username"
						placeholder="e.g. hadrbari024"
						onChange={console.log}
					/>
				</div>

				<div className="m-b-xs" >
					<PasswordField
						label="Password"
						placeholder="e.g. *******"
						onChange={console.log}
					/>
				</div>

				<div className="m-b-xs" >
					<TextField
						label="Coupon code"
						placeholder="If you are refered by someone"
						onChange={console.log}
					/>
				</div>

				<span className="disclaimer" >If you already have an account, log in <a href="/login">here</a>.</span>

				<MainCTA href="/lineup" className="primary m-t-s" >Register</MainCTA>
			</div>
		</div>
	);
};

export default Register;