const config = {
	BASE_API_PATH: "https://api.fcm-game.com",
	AUTHORIZATION_HEADER_NAME: "Authorization",
	BEARER_TOKEN_IDENTIFIER: "Bearer",
	LOCAL_STORAGE_TOKEN_KEY: "token",
	PAGE_SIZE: 3,
	MAX_IMAGE_SIZE: 2097152,
	STRIPE_KEY: "",
	COLORS: {
		BLUE: "#10abe2",
		RED: "#d91f2b",
		GREEN: "#00A699",
		YELLOW: "#eabb53",
		ORANGE: "#ff4d01",
		BLACK: "#000",
		GRAY: "#353434",
		WHITE: "#fff",
		LIGHT_GRAY: "#f3f3f3",
		DARK_GRAY: "#252525",
	}
};

export default config;
