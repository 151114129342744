import Slider from "react-slick";

import ContentLimiter from "../components/ContentLimiter";
import FighterSlide from "../components/fighter/FighterSlide";
import DataTable from "../components/DataTable";
import MainCTA from "../components/form/MainCTA";
import FighterSubcontent from "../components/fighter/FighterSubcontent";

const Lineup = () => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<ContentLimiter>
			<div className="m-t-s">
				<div>
					<span className="banner-track black large" >Your lineup</span>
				</div>
				<div>
					<span className="banner-track primary medium" >Slide to change division</span>
				</div>
			</div>

			<div className="p-h-s" >
				<Slider {...settings}>
					<FighterSlide />
					<FighterSlide />
					<FighterSlide />
				</Slider>
			</div>

			<div className="m-v-m" >
				<DataTable
					fields={{
						rank: {
							text: "Rank",
							className: "col-1",
						},
						name: {
							text: "Name",
							className: "col-5",
						},
						price: {
							text: "Price",
							className: "col-4",
						},
						actions: {
							text: "",
							className: "col-2",
							renderer: (item) => <MainCTA className="negative m-r-xxs" >Buy</MainCTA>
						},
					}}
					data={[
						{
							rank: 1,
							name: "Islam Kakhachev",
							price: "800 000$",
						},
						{
							rank: 2,
							name: "Bon Bones",
							price: "600 000$",
						},
						{
							rank: 3,
							name: "Edward Leones",
							price: "400 000$",
						},
					]}
					subcontentRenderer={item => <FighterSubcontent />} />
			</div>
		</ContentLimiter>
	);
};

export default Lineup;