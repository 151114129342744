import PasswordField from "../../components/form/PasswordField";
import MainCTA from "../../components/form/MainCTA";

const SubmitNewPassword = () => {

	return (
		<div className="auth-page flex-center">
			<div className="auth-form flex-column">
				<h2 className="m-b-s" >Reset password</h2>

				<div className="m-b-xs" >
					<PasswordField
						label="New password"
						placeholder="e.g. *******"
						onChange={console.log}
					/>
				</div>

				<div className="m-b-xs" >
					<PasswordField
						label="Repeat it"
						placeholder="e.g. *******"
						onChange={console.log}
					/>
				</div>

				<MainCTA href="/account/reset/finish" className="primary m-t-s" >Submit</MainCTA>
			</div>
		</div>
	);
};

export default SubmitNewPassword;