import { Slider } from "react-burgers";
import { slide as Menu } from "react-burger-menu";
import { Outlet } from "react-router";
import { useState } from "react";

import DropdownField from "../components/form/DropdownField";
import FighterCard from "../components/fighter/FighterCard";
import EmptyFighterCard from "../components/fighter/EmptyFighterCard";

export default function AppLayout() {
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [isMyLineupOpen, setMyLineupOpen] = useState(false);

	return (
		<div id="outer-container">
			<Menu
				isOpen={isMenuOpen}
				onClose={() => setMenuOpen(false)}
				className="app-layout-menu"
				pageWrapId="page-wrap"
				outerContainerId="outer-container">

				<a href="/lineup" >
					<img className="app-menu-logo m-b-m" src="/images/logo-red.svg" alt="" />
				</a>

				<a className="menu-item" href="/lineup">Lineup</a>
				<a className="menu-item" href="/profile">Profile</a>
				<a className="menu-item" href="/leaderboard">Leaderboard</a>
				<a className="menu-item" href="/fights">Fights</a>
				<a className="menu-item m-t-m" href="/">Sign out</a>

				<div className="bottom-content">
					<div className="social-share-container m-b-s p-h-s">
						<img src="/images/icons/social/facebook-white.svg" alt="" />

						<img src="/images/icons/social/x-white.svg" alt="" />

						<img src="/images/icons/social/tiktok-white.svg" alt="" />

						<img src="/images/icons/social/copy-white.svg" alt="" />
					</div>

					<div className="p-h-s">
						<DropdownField white>
							<option>English</option>
							<option>Srpski</option>
						</DropdownField>
					</div>
				</div>
			</Menu>

			<Menu
				right
				isOpen={isMyLineupOpen}
				onClose={() => setMyLineupOpen(false)}
				className="my-lineup-menu"
				pageWrapId="page-wrap"
				outerContainerId="outer-container">

				<div className="m-b-s" >
					<EmptyFighterCard
						division="Lightweight"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Blustin Baethje"
						division="Middleweight"
						image="/images/fighter-2.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Salex Poirot"
						division="Heavyweight"
						image="/images/fighter-3.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Islam Kakhachev"
						division="Lightweight"
						image="/images/fighter-1.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Blustin Baethje"
						division="Middleweight"
						image="/images/fighter-2.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Salex Poirot"
						division="Heavyweight"
						image="/images/fighter-3.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Islam Kakhachev"
						division="Lightweight"
						image="/images/fighter-1.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Blustin Baethje"
						division="Middleweight"
						image="/images/fighter-2.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Salex Poirot"
						division="Heavyweight"
						image="/images/fighter-3.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Islam Kakhachev"
						division="Lightweight"
						image="/images/fighter-1.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Blustin Baethje"
						division="Middleweight"
						image="/images/fighter-2.webp"
					/>
				</div>

				<div className="m-b-s" >
					<FighterCard
						name="Salex Poirot"
						division="Heavyweight"
						image="/images/fighter-3.webp"
					/>
				</div>
			</Menu>



			<header className="app-layout-header">
				<div className="burger-button-wrapper">
					<Slider
						width={26}
						lineHeight={2}
						lineSpacing={5}
						borderRadius={1}
						active={isMenuOpen}
						onClick={() => setMenuOpen(!isMenuOpen)} />
				</div>
				<div className="header-logo-container">
					<div className="header-logo-skew"></div>

					<a href="/lineup" >
						<img src="/images/logo-white.svg" alt="" />
					</a>
				</div>

				<a className="header-player-stats" href="/profile" >
					<span>200,000 <span className="stat-icon">$</span></span>
					<span>186 <span className="stat-icon">▲</span></span>
				</a>
			</header>

			<main id="page-wrap" className="app-layout-main">
				<Outlet />
			</main>

			<button
				className="my-lineup-button"
				onClick={() => setMyLineupOpen(!isMyLineupOpen)}
			>
				<span>My lineup</span>
				<img src={`/images/icons/${isMyLineupOpen ? "close" : "octagon"}.svg`} alt="" />
			</button>
		</div>
	)
}
