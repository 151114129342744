import { useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Checkout from "../components/Checkout";

const stripePromise = loadStripe(
	"pk_test_51Oqwk2Gv2CO2GwGKi8iK5eoIQn2FqKECGxbqRJAqg1VZFp2kq9esrLEf1giOYDRPdBHVNDp4phDoctkngvJcRbK900x9TJnnnQ"
);

const CheckoutPage = () => {
	const hardcodedUserId = "65ea599e8c9bb3f30deda08a";
	const [clientSecret, setClientSecret] = useState("");

	const makePaymentIntent = async () => {
		//zakucani id korisnika iz baze

		const body = {
			name: "Premium membership",
			amount: 500,
			quantity: 2,
			currency: "usd",
			userId: hardcodedUserId,
		};

		const headers = {
			"Content-Type": "application/json",
		};

		const response = await fetch(
			"http://localhost:5000/stripe/create-checkout-session",
			{
				method: "POST",
				headers: headers,
				body: JSON.stringify(body),
			}
		);

		const data = await response.json();

		setClientSecret(data.clientSecret);
	};

	if (!clientSecret)
		return (
			<div className="app">
				<section>
					<h1>Fight manager</h1>
				</section>

				<button onClick={() => makePaymentIntent()}>Pay</button>
			</div>
		);

	return (
		<Elements
			stripe={stripePromise}
			options={{
				clientSecret,
			}}
		>
			<Checkout />
		</Elements>
	);
};

export default CheckoutPage;