import { Link } from "react-router-dom";

const MainCTA = ({ href = undefined, disabled = false, className = "", children = null, onClick = () => null }) => {
    return href && href.length ? (
        <Link className={`btn ${className}`} to={href}>
            {children}
        </Link>
    ) : (
        <button onClick={onClick} className={`btn ${className}`} disabled={disabled}>
            {children}
        </button>
    );
};

export default MainCTA;
