import DonutStat from "./DonutStat";

const FighterSubcontent = () => {
	return (
		<div className="fighter-subcontent" >
			<div className="fighter-subcontent-image" style={{ backgroundImage: 'url("/images/fighter-2.webp")' }} ></div>

			<div className="fighter-stats">
				<h2 className="m-t-s m-b-xs" >Rondon Brayal</h2>
				<h5>Flyweight division</h5>
				<h5 className="m-b-xs" >16-7-0</h5>

				<div className="fighter-stat-container">
					<span className="stat-value">4</span>
					<span className="stat-name" >Wins by knockout</span>
				</div>
				<div className="fighter-stat-container">
					<span className="stat-value">9</span>
					<span className="stat-name" >Wins by submission</span>
				</div>
				<div className="fighter-stat-container">
					<span className="stat-value">10</span>
					<span className="stat-name" >Wins by decision</span>
				</div>

				<div className="flex-row">
					<DonutStat percentage={100} statName="Striking accuracy" />
					<DonutStat percentage={86} statName="Takedown accuracy" />
				</div>
			</div>

		</div>
	);
};

export default FighterSubcontent;