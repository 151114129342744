import Avatar from "boring-avatars";

import ContentLimiter from "../components/ContentLimiter";
import NumberField from "../components/form/NumberField";
import TextField from "../components/form/TextField";
import CheckboxField from "../components/form/CheckboxField";
import PasswordField from "../components/form/PasswordField";
import MainCTA from "../components/form/MainCTA";

import config from "../config";
import timeAgo from "../utils/timeAgo";

const Profile = () => {
	return (
		<ContentLimiter>
			<div className="col-4 col-m-12 m-t-m">
				<div className="flex-centered-column" >
					<div className="avatar-container m-b-xs" >
						<Avatar
							size={"100%"}
							name="kockarevicivan"
							variant="pixel"
							colors={[config.COLORS.RED, config.COLORS.GRAY, config.COLORS.WHITE]}
						/>
					</div>

					<span className="banner-track black medium" >kockarevicivan</span>
					<span className="banner-track primary small" >Joined {timeAgo.format(Date.now() - 2 * 60 * 60 * 1000)}</span>
				</div>
			</div>
			<div className="col-8 col-m-12 m-t-m">

				<div className="season-details p-b-m m-b-m bottom-border" >
					<div>
						<span className="profile-season-banner banner-track black large" >Season 200 member</span>
					</div>
					<div>
						<span className="banner-track primary medium" >Currently 186th place</span>
					</div>
				</div>

				<div className="p-b-m m-b-m bottom-border" >
					<h3 className="m-b-s" >Join the current season</h3>

					<p>By clicking on the join button, you will participate in the current season and possibly win one of the prizes! Current price for joining is 500 000$</p>

					<MainCTA className="primary m-t-s" >Join!</MainCTA>
				</div>


				<div className="p-b-m m-b-m bottom-border" >
					<h3 className="m-b-s" >Add coins to your account</h3>

					<div>
						<div className="col-6 col-m-12">
							<NumberField
								label="Coins"
								placeholder="Coins to buy"
								value={10}
								onChange={console.log}
							/>
						</div>
						<div className="col-6 col-m-12">
							<TextField
								label="Price"
								placeholder="Price to pay for the coins"
								value="20$"
								readOnly
								onChange={console.log}
							/>
						</div>
					</div>

					<MainCTA className="primary m-t-s" >Add coins</MainCTA>
				</div>


				<div className="p-b-m m-b-m bottom-border" >
					<h3 className="m-b-s" >Change profile information</h3>

					<div className="m-b-xs" >
						<TextField
							label="E-mail"
							placeholder="e.g. name@domain.com"
							value="kockarevic.ivan@gmail.com"
							errorMessage="This field is required."
							mandatory
							onChange={console.log}
						/>
					</div>

					<div className="m-b-xs" >
						<TextField
							label="Username"
							placeholder="e.g. name2024"
							value="kockarevicivan"
							errorMessage=""
							mandatory
							readOnly
							onChange={console.log}
						/>
					</div>

					<div className="m-b-xs" >
						<CheckboxField
							label="Private profile"
							value={true}
							onChange={console.log}
						/>
					</div>

					<MainCTA className="m-t-s" >Save changes</MainCTA>
				</div>

				<div className="p-b-m" >
					<h3 className="m-b-s" >Change password</h3>

					<div className="m-b-xs" >
						<PasswordField
							label="Password"
							placeholder="e.g. f89sh30adf023j"
							value="f89sh30adf023j"
							errorMessage=""
							mandatory
							readOnly
							onChange={console.log}
						/>
					</div>


					<div className="m-b-xs" >
						<PasswordField
							label="Repeat password"
							placeholder="Retype your password"
							value="f89sh30adf023j"
							errorMessage=""
							mandatory
							readOnly
							onChange={console.log}
						/>
					</div>


					<MainCTA className="m-t-s">Change password</MainCTA>
				</div>
			</div>

		</ContentLimiter>
	);
};

export default Profile;