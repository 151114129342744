import Countdown from "react-countdown";
import moment from "moment";
import MainCTA from "../form/MainCTA";

const FightCard = ({ date }) => {
	const now = new Date();
	const isPastFight = date.getTime() < now.getTime();

	return (
		<div className={`fight-card ${isPastFight ? "past" : ""}`} >
			<div className="flex-distributed-row m-b-s">
				<span className="banner-track primary medium" >Red<span className="desktop-and-tablet-content"> corner</span></span>
				<span className="banner-track blue medium" >Blue<span className="desktop-and-tablet-content"> corner</span></span>
			</div>
			
			<div className="flex-center">
				<div className="fighter-fight-image left" style={{ backgroundImage: 'url("/images/fighter-3.webp")' }}></div>
				<div className="fighter-fight-image right" style={{ backgroundImage: 'url("/images/fighter-1.webp")' }}></div>
			</div>

			<div className="fight-details flex-centered-column m-b-xs" >
				<div className="breakable-background-text">
					<span className="banner-track black large" >Baethje</span>
					<span className="banner-track black large" > vs </span>
					<span className="banner-track black large" >Kakhachev</span>
				</div>

				{!isPastFight && <span className="banner-track primary medium" >
					<span className="desktop-and-tablet-content">COUNTDOWN: </span><Countdown date={date} />
				</span>}
				{isPastFight && <span className="banner-track primary medium" >{moment(date).format("MMM Do YYYY, h:mm a")}</span>}
			</div>

			<div className="flex-distributed-row">
				<MainCTA className="primary" >Blustin Baethje</MainCTA>
				
				{!isPastFight && <span className="banner-track gray small m-h-xs" ><span className="desktop-and-tablet-content" >Odds</span> 1:3</span>}

				<MainCTA className="blue" >Islam Kakhachev</MainCTA>
			</div>
		</div>
	);
};

export default FightCard;