import MainCTA from "../form/MainCTA";
import { useNavigate } from "react-router-dom";

const EmptyFighterCard = ({ division = "" }) => {
	const navigate = useNavigate();

	return (
		<div className="fighter-card" >
			<div className="fighter-image" style={{ backgroundImage: `url(https://pics.craiyon.com/2023-06-30/873aadf23dea4701b049065722ae0eec.webp)` }}>
			</div>

			<div className="fighter-info">
				<MainCTA onClick={() => navigate("/lineup")} >Add fighter</MainCTA>
				{division && <span className="banner-track primary small" >{ division }</span> }
			</div>
		</div>
	);
};

export default EmptyFighterCard;