import { useState } from "react";

const DataTable = ({ fields, data, subcontentRenderer }) => {
	const [selectedItem, setSelectedItem] = useState(1); // TODO: Consider different default value.

	const tableHeader = () => {
		return Object.values(fields).map((field, index) => (
			<div key={index} className={`column ${field.className}`}>
				<span>{field.text}</span>
			</div>
		));
	};

	const tableBody = () => {
		return data.map((item, index) => (
			<div key={index} className="row" onClick={() => setSelectedItem(item.rank)}>
				<div className="row-content">

					{Object.keys(fields).map((key, index) => (
						<div key={index} className={`column ${fields[key].className}`}>
							{fields[key].renderer ? fields[key].renderer(item) : <span>{item[key]}</span>}
						</div>
					))}
				</div>

				{subcontentRenderer && <div className={`row-subcontent ${item.rank === selectedItem ? "active" : ""}`}>
					{subcontentRenderer(item)}
				</div>}
			</div>
		));
	};

	return (
		<div className="data-table" >
			<div className="data-table-header">
				{tableHeader()}
			</div>

			<div className="data-table-body">
				{tableBody()}
			</div>
		</div>
	);
};

export default DataTable;